@use "./styles/base";
@use "./styles/fonts";

$body-bg: #ffffff;
$body-fg: #000000;
$font-weight-semi-bold: 600;
$font-weight-mid: 500;
$font-weight-regular: 400;

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.App {
  @include fonts.proxima-nova-medium;
  background-color: $body-bg;
  color: $body-fg;
  font-size: 14px;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  & > div {
    height: 100%;
  }
}

header {
  height: 10vh;
  width: 100vw;
  padding: 0 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

main {
  @include base.centered;
  padding: 0em 0.75rem;
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  min-height: 85vh;
  max-height: 85vh;
  width: 100vw;
  overflow: auto;
}

footer {
  @include base.centered;
  flex-shrink: 0;
  color: base.$dark;
  padding: 1rem;
  min-height: 5vh;

  small {
    font-size: 0.65rem;
  }
}

label {
  @include fonts.proxima-nova-bold;
  font-style: italic;
  // font-size: 0.75rem;
  color: base.$dark;

  &.or {
    padding-bottom: 0.35em;
  }
}

form {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  width: 100%;
  max-width: 664px;
  padding: 1rem 2rem 0.5rem;
  align-items: center;

  input {
    font-size: 16px;
  }
}

fieldset {
  width: 100%;
  padding: 0 1rem 1rem;
  border: 1px solid #e4e4e2;
}

legend {
  padding: 1rem;
}

.input-group {
  display: flex;
  flex-wrap: nowrap;
  gap: 0.5rem;
  width: 100%;
  max-width: 664px;
}

.input-field {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  width: 100%;
  max-width: 664px;

  label {
    align-self: flex-start;
    font-style: normal;
  }

  input {
    width: 100%;
    height: 2rem;
    text-indent: 0.5rem;
  }
}

.customer-name {
  color: #00a1ed;
}

h1 {
  font-size: 2.25em;
  font-weight: $font-weight-semi-bold;
}

h2 {
  font-size: 2em;
  font-weight: $font-weight-semi-bold;
}

h3 {
  font-size: 1.571em;
  font-weight: $font-weight-regular;
  max-width: 600px;
}

h4 {
  font-size: 1.143em;
  font-weight: $font-weight-semi-bold;
}

pre {
  @include fonts.proxima-nova-medium;
  white-space: pre-wrap;
  max-width: 90vw;
}

i::before {
  padding: 0 0.5rem;
}

.companylogo {
  max-height: 25vh;
  max-width: 75vw;
}

.card {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #2f292b;
  border-radius: 1rem;
  padding: 1rem;
  margin: 1.5rem;
  width: fit-content;
  color: white;

  h4 {
    text-align: left;
    margin: 0.25rem 0;
  }

  img {
    min-height: 20vh;
  }
}

.list-container {
  margin: auto;
}

ol {
  list-style: none;
  margin: 0.25rem 0;
  counter-reset: my-awesome-counter;

  li {
    counter-increment: my-awesome-counter;
    text-align: left;
    font-size: 1em;
    margin: 10px 0.25rem;
  }

  li::before {
    content: counter(my-awesome-counter);
    background: #1f9ed5;
    font-weight: bold;
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 50%;
    display: inline-block;
    line-height: 1.5rem;
    color: white;
    text-align: center;
    margin-right: 0.5rem;
  }
}

input[type="file"] {
  display: none;
}

.custom-file-upload {
  display: block;
  font-size: 1.286em;
  color: white;
  cursor: pointer;
  padding: 1rem 0;
  border: 1px solid white;
  font-style: normal;
  border-radius: 3px;
  width: 140px;
}

a.button {
  color: black;
}

.button {
  display: block;
  box-sizing: border-box;
  min-width: 215px;
  text-decoration: none;
  padding: 1rem 0;
  border-radius: 3px;
  font-size: 1.286em;
  cursor: pointer;
}

.button__video-recording {
  background-color: white;
  color: #000000;
  font-style: normal;
  min-width: 130px;
  width: 140px;
}

.button__size-small {
  font-size: 16px;
  line-height: 16px;
}

.button__primary {
  background-color: #1f9ed5;
  color: #fff !important;
}

.button__primary:hover {
  background-color: #125f7d;
  color: black;
}

.button__primary:disabled {
  background-color: #64b8de;
  color: #868e96;
}

.button__secondary {
  background-color: lightgray;
  color: #000;
}

.button__secondary:hover {
  background-color: darkgray;
}

.button__secondary:disabled {
  background-color: darkgray;
  color: #868e96;
}

button {
  all: unset;
}

.button-group {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  gap: 1rem;
  width: 100%;
  max-width: 400px;

  .button {
    min-width: 144px;
  }
}

.button-group.button-group--column {
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: start;
  align-items: center;
  flex: 2;

  .button {
    min-width: 215px;
  }
}

//inviteLandingPage
.main__invite-landing {
  font-size: calc(16px + 2vmin);
  min-height: 75vh;
  padding: 1rem;
  justify-content: normal;
  gap: 1rem;
}

.trademark-footer {
  height: 10vh;
  font-size: 0.5rem;
}

//instructions page
.main__instructions {
  .card {
    margin: 1.5rem 0;
    max-width: 600px;
  }

  background-color: #f2f2f2;
  min-height: 60vh;
  max-height: 100vh;
  justify-content: start;
  padding: 1.5rem;
  flex: 1;
  padding-bottom: 130px;

  .video-container {
    position: relative;

    .video-poster {
      max-width: 100%;
    }
  }

  .play-pause {
    border-radius: 50px;
    background-color: white;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-left: -25px;
    margin-top: -25px;
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;

    .play {
      color: #000;
    }
  }
}

.instructions-footer {
  display: flex;
  flex: 1;
  gap: 1rem;
  flex-direction: row;
  align-items: center;
  background-color: #1f9ed5;
  width: 100%;
  max-height: 130px;
  position: fixed;
  bottom: 0;
}

//upload page
.loading-header {
  background-color: #f2f2f2;
  justify-content: space-between;
}

.main__uploading {
  min-height: 600px;
}

.uploading-progress {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.form-divider {
  width: 100%;
  display: flex;
  gap: 0.5rem;
  align-items: center;
  justify-content: center;
  padding-top: 0.5rem;

  hr {
    border: 0;
    border-bottom: 1px solid #e4e4e2;
    flex-grow: 1;
  }
}

.terms {
  overflow: auto;
  font-size: 0.875rem;
  flex: 2;
  max-height: 60vh;
  border: 1px solid #e4e4e2;
  min-height: 20vh;
  max-width: 600px;
  padding: 1rem;
  margin: 1rem 2rem;
  text-align: left;

  p {
    padding-bottom: 1rem;
  }
}

.accept-controls {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

.tip {
  font-size: 1rem;
  color: #868e96;
}

.tip-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
}

.noVideo {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

// Thankyou page
.main__thankyou {
  background-color: #f2f2f2;
  min-height: 85vh;
  justify-content: normal;
  flex: 1;
}

.thankyou-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1.5rem 0;
  max-width: 100%;
  gap: 0.5rem;

  .thankyou-more {
    padding-top: 1.5rem;
  }
}

@media only screen and (min-width: 600px) {
  .App {
    font-size: 16px;
  }

  .main__instructions {
    background-color: #f2f2f2;
    min-height: 60vh;
    flex: 1;
  }
}
