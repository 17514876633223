@use "../../styles/base";
@use "../../styles/fonts";

.review-site-button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 244px;
  cursor: pointer;

  &.primary {
    height: 56px;
    border-radius: 3px;
    background-color: #757575;
    &.GOOGLE {
      background-color: #ea4235;
    }

    &.DEALERRATER {
      background-color: #f26a2b;
    }

    &.FACEBOOK {
      background-color: #1877f2;
    }

    &.CARS_DOT_COM {
      background-color: #703b95;
    }

    &.EDMUNDS {
      background-color: #017ee5;
    }

    &.APARTMENTS_DOT_COM {
      background-color: #72b944;
    }

    &.APARTMENT_RATINGS {
      background-color: #155497;
    }

    .label {
      @include fonts.proxima-nova-bold;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #ffffff;
      width: 100%;
      height: 100%;
      font-size: 0.85rem;
      padding: 0.5em;
    }

    .logo {
      min-width: 48px;
      max-width: 48px;
      height: 48px;
      background-color: #ffffff;
      padding: 0.5rem;
      border-radius: 2px;
      margin: 4px;
      display: flex;
      justify-content: center;
      align-items: center;
      .logo-img {
        max-width: 100%;
        max-height: 100%;
      }
    }
  }

  &.secondary {
    @include base.bordered;
    height: 36px;
    padding: 0.5rem 1rem;
    @include fonts.proxima-nova-bold;
    color: #9096a0;
    font-size: 1rem;

    .logo {
      max-width: 112px;
      max-height: 80%;
    }
  }
}
