.primary-sites {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 90%;
  max-width: 300px;
  gap: 1rem;

  .review-site-button {
    // padding-bottom: 1em;
  }
}

.secondary-sites {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 90%;
  max-width: 300px;

  .review-site-button {
    margin-bottom: 0.25rem;
  }
}
