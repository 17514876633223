.surveyWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 800px;
  margin: 0 auto;

  .surveyQuestion {
    align-items: center;
  }
}

.welcome {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.surveyQuestion {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.surveyQuestionControls {
  display: flex;
  flex: 0;
  gap: 4rem;
}

.surveyQuestionText {
  margin-bottom: 1rem;
  padding-bottom: 1rem;
  flex: 0;
}

.surveyQuestionBody {
  margin-bottom: 1rem;
  padding-bottom: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.freeText {
  display: flex;
  flex-direction: column;

  &>textarea {
    margin: 0.5rem;
    padding: 8px;
    font-size: 16px;
  }
}

.oneToFiveLabels,
.oneToFiveStars {
  font-size: 0.5em;
  display: flex;
  flex: 0;
  justify-content: space-between;
  align-items: center;
}

.oneToFiveLabels {
  padding: 0 0.75rem;
}

.oneToFiveStar {
  color: #fff;
  text-shadow: 0 0 3px #000;
  font-size: 4.5em !important;
}

.oneToFiveStarSelected {
  color: gold;
  transform: scale(1.25);
  transition: all 250ms ease;
}

.yesNo {
  flex: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}

.yesNoButton span {
  margin-left: -1.5em;
}

.yesNoButton i {
  margin-right: 0.5em;
}

.nps {
  display: flex;
  flex: 0;
  flex-direction: column;
  justify-content: center;
}

.npsRatings {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 0.25em 0.25em;
  font-size: 0.7em;
}

.npsLabels {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  font-size: 16px;
  color: #666;
}

.npsButton {
  $size: 1.75em;

  min-width: unset;
  font-size: $size;
  width: $size;
  height: $size;
  display: block;
  border-radius: 100%;
  line-height: 0px;
  transition: all 250ms ease;
}

.selectedNps {
  border-radius: 4px;
  color: #fff !important;
}

.surveyProgress {
  font-size: 16px;
  color: #555;
}
