//Proxima Nova Font Stack
@mixin proxima-nova-regular {
  font-family: proxima-nova, sans-serif;
  font-weight: 400;
  font-style: normal;
}

@mixin proxima-nova-medium {
  font-family: proxima-nova, sans-serif;
  font-weight: 500;
  font-style: normal;
}

@mixin proxima-nova-bold {
  font-family: proxima-nova, sans-serif;
  font-weight: 600;
  font-style: normal;
}

//Proxima Nova Font Stack end

// Fonts added: from https://use.typekit.net/xdq6lsd.css this is from Jake Hughes Adobe Creative Cloud subscription attached to jake@widewail.com 

// Proxima Nova Black
// font-family: proxima-nova, sans-serif;
// font-weight: 900;
// font-style: normal;

// Proxima Nova Black Italic
// font-family: proxima-nova, sans-serif;
// font-weight: 900;
// font-style: italic;

// Proxima Nova Bold
// font-family: proxima-nova, sans-serif;
// font-weight: 700;
// font-style: normal;

// Proxima Nova Bold Italic
// font-family: proxima-nova, sans-serif;
// font-weight: 700;
// font-style: italic;

// Proxima Nova Extrabold
// font-family: proxima-nova, sans-serif;
// font-weight: 800;
// font-style: normal;

// Proxima Nova Extrabold Italic
// font-family: proxima-nova, sans-serif;
// font-weight: 800;
// font-style: italic;

// Proxima Nova Thin
// font-family: proxima-nova, sans-serif;
// font-weight: 100;
// font-style: normal;

// Proxima Nova Thin Italic
// font-family: proxima-nova, sans-serif;
// font-weight: 100;
// font-style: italic;

// Proxima Nova Semibold
// font-family: proxima-nova, sans-serif;
// font-weight: 600;
// font-style: normal;

// Proxima Nova Semibold Italic
// font-family: proxima-nova, sans-serif;
// font-weight: 600;
// font-style: italic;

// Proxima Nova Regular
// font-family: proxima-nova, sans-serif;
// font-weight: 400;
// font-style: normal;

// Proxima Nova Italic
// font-family: proxima-nova, sans-serif;
// font-weight: 400;
// font-style: italic;

// Proxima Nova Light
// font-family: proxima-nova, sans-serif;
// font-weight: 300;
// font-style: normal;

// Proxima Nova Light Italic
// font-family: proxima-nova, sans-serif;
// font-weight: 300;
// font-style: italic;

// Proxima Nova Medium
// font-family: proxima-nova, sans-serif;
// font-weight: 500;
// font-style: normal;

// Proxima Nova Medium Italic
// font-family: proxima-nova, sans-serif;
// font-weight: 500;
// font-style: italic;
