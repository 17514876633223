$light: #E9E8E2;
$dark: #6F6764;
$radius: 2px;

@mixin bordered {
  border: 1px solid $light;
  border-radius: $radius;
}

@mixin centered {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.centered { 
  @include centered;
}

$spacingFactor: 0.15;

.pb-1 {
  padding-bottom: $spacingFactor * 1em;
}

.float-right {
  float: right;
}

button.link {
  background: none!important;
  border: none;
  padding: 0!important;
  color: #069;
  text-decoration: underline;
  cursor: pointer;
}